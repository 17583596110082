import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'

function МетодологияРабот() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.гарантии_раздел_методологияРабот["title"]}</title>
    </Helmet>      
    {/* это у нас шапка */}
        <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Методология выполнения работ</h1>          
          <div style={{paddingTop:"5px"}}>поддержание четкой цепочки действий в работе даёт 100% уверенность нам и заказчику в результате</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/*это у нас стилистическая перебивка */}
    <div className="col-lg-12" style={{margin:"auto", marginBottom:"30px"}}><Image src="/pageImages/method.png" fluid /></div>

    {/* теперь содержание из блоков */}
    {/* заголовок составные части */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"0px"}}>
          <h1 style={{fontWeight:"bolder"}}>Составные части методологии:</h1>          
        </div>
    </div>
    <div style={{textAlign:"center", fontSize:"25px"}}>~</div>    

    {/* три составные части */}
    <div className="row">

    <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>ПОНИМАНИЕ</Card.Title>
          <Card.Text>
          Мы обеспечиваем заказчику стопроцентное понимание того, что можно делать в продажах и чего нельзя. 
          Передаем стандарты, на основании которых можно делать материалы его типа. 
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/education.svg" fluid /></div>        
        </div>
      </Card>
      </div>

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>МАТЕРИАЛЫ</Card.Title>
          <Card.Text>
          Мы создаём, при необходимости, базовый набор материалов, включающий материалы 
          по основным продуктам, в целом по компании, по специалистам заказчика. 
          Добиваемся стопроцентной воспроизводимости ситуации.
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/certificates.svg" fluid /></div>
        </div>
      </Card>
      </div>      

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>ПОДДЕРЖКА</Card.Title>
          <Card.Text>
          Мы поддерживаем заказчика на протяжении всего времени его коммерческого 
          пути и даём рекомендации о том, когда и что можно использовать, когда что лучше 
          использовать и от чего лучше отказаться. Предоставляем понятное обоснование.
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/document.svg" fluid /></div>    
        </div>
      </Card>
      </div>
      
    </div>

    <div>&nbsp;</div>    

    {/* теперь "почему это работает" */}
    {/* заголовок */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"0px"}}>
          <h1 style={{fontWeight:"bolder"}}>Почему это работает:</h1>          
          <div style={{paddingTop:"5px"}}>есть два крупных блока, каждый из которых поддерживает возможность улучшения и систематизации</div>
        </div>
    </div>
    <div style={{textAlign:"center", fontSize:"25px"}}>~</div> 

    {/* две составные части */}
    <div className="row">

    <div className="col-md-6  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"left"}}>:: Стандарты</Card.Title>
          <Card.Text>
          Стандарты это конкретные наборы правил по отношению к определенному типу документов.
          Могут быть использованы вплодь до включения в задание/контракт для аутсорс работников. 
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
      </Card>
      </div>

      <div className="col-md-6  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"left"}}>:: Шаблоны/драфты</Card.Title>
          <Card.Text>
          Шаблоны материалов позволяют заказчику самостоятельно воссоздать коммерчески значимые материалы. Систематизация шаблонов позволяет увидеть, какие материалы есть в компании и для чего они предназначены.
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
      </Card>
      </div>     

      <div>&nbsp;</div> 
      <div>&nbsp;</div> 

      <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 

    </div>

    <div>&nbsp;</div> 

    </>
  )
}

export default МетодологияРабот