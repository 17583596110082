import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"

function ПредложениеИЦены() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.предложениеИЦены["title"]}</title>
    </Helmet>     
    "коммерческое предложение и цены на обслуживание"
    </>
  )
}

export default ПредложениеИЦены