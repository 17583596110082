import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import { useParams } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack'
import Image from 'react-bootstrap/Image'

const link = "чтоТакоеПродажи"
const thumb = "blogImages/001.png"
const title = `Что такое продажи`
const shortDescription = `
В этой публикации мы расскажем о том, что на самом деле представляют собой продажи и продажные нарративы, а также о том, как интерпретировать и классифицировать материалы, которые продают.<br>
<br>
В последнее время роль продаж всё больше переходит к менеджерам, в то время как функция рекламы выделяется в отдельную категорию. Однако на самом деле от рекламы ожидается то же, что и от хорошего продавца: донести информацию и привести потенциального клиента к запланированному действию.<br>
`
const mainText = `
<p>
В этой публикации мы расскажем о том, что такое в действительности представляют собою продажи, что такое продажные нарративы, 
и как инрерпретировать и классифицировать материалы которые продают.
</p>

<p>
Первое, что нужно понимать относительно продаж, то что это коммуникация, 
но коммуникация не просто так, а коммуникация с целью донести определенную идею.
</p>

<p>
А как это будет доноситься и кому, это как раз и есть два основные вопроса на которые должен ответить тот кто продажи осуществляет
</p>

<p>
Правильно и обратное, в случае если идея не доноситься, она отсутствует, или она размазана, - продажа не осуществляется,
так же как и если вы обращаетесь не понятно к кому и не понятно как.
</p>

<p>
Наболее часто когда мы разбираем материалы заказчиков мы видим как раз проблему связанную с несоблюдением этих двух базовых правил, - 
продавец пытается рассказать и поделиться массой идей относительно чего то, и делает это на неопределенный круг лиц.
</p>

<p>
В итоге мы можем наблюдать вот такую ситуацию:
</p>

<p>
<i>
Приходите к нам в кафе или в ресторан или мы доставим вам товары домой, у нас отличные скидки на заказ столика 
с утра до обеда для многодетных семей в день рождения
</i>
</p>

<p>
В примере ошибка максимально очевидна, так как здесь скомканы и идея и нарратив и те к кому мы обращаемся.
</p>

<p>
Но это об основной ошибке, что же такое продажа в целом, - здесь тоже нет ничего сложного или секретного для понимания
</p>

<p>
Для того чтобы понять с кем вы собираетесь общаться вы выделяете диспозицию, то определение того кем является ваш потенциальный покупатель,
его больные места и возможности. После чего передавая одной идеей основную мысль её последовательно и передаете.
</p>

<p>
В итоге получается чтото вроде:
</p>

<p>
<i>
Кафе с летней верандой для романтических свиданий. Мягкая погружающая музыка, приятное вино, 
и вежливые оффицианты составят о вас только лучшее впечатление.
</i>
</p>

<p>
<b>Диспозиция здесь</b>: пара идет на свидание, наша аудитория это приглашающее лицо 
</p>

<p>
<b>Основная идея</b>: на свидании важно произвести о себе впечатление
</p>

<p>
<b>Развитие основной идеи</b>: это можно сделать в нашем кафе
</p>

<p>
Заметьте также, что мы выделили акценты на компонентах описания и добавили тексту стилистику.
</p>

<p>
По сути эта простая формула и должна вам теперь понимать минимально в том чем хороший нарратив отличается от плохого,
и должна позволить классифицировать материалы на хорошие продажные и плохие продажные, так как элементы всегда слишком очевидны 
для того чтобы их идентифицировать.
</p>

<p>
Сама модель продаж, то есть через какой канал вещание нарратива производится имеет значение, но формула при этом изменению 
подлежать не будет.
</p>

<p>
Надеемся это краткое, но объемлющее знание позволит вам лучше ориентироваться в том что такое на самом деле продажи. Спасибо за внимание.
</p>

`

function X01_чтоТакоеПродажи_short(){
  return (
    <>
    короткое от что такое продажи 
    </>
  )
}

function X01_чтоТакоеПродажи() {
  let { nameOfEntry } = useParams();
  
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>что такое продажи</title>
    </Helmet>      
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>{title}</h1>          
          <div style={{paddingTop:"5px"}}>в этой публикации мы расскажем о том что же такое в действительности продажи, продажные нарративы,
          и как интерпретировать и классифицировать материалы которые продают</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/* бейджики */}
      <Badge bg="primary" style={{margin:"5px"}}>Как работают продажи?</Badge>
      <Badge bg="secondary" style={{margin:"5px"}}>Почему одно продает лучше другого?</Badge>
      <Badge bg="success" style={{margin:"5px"}}>Как оценить эффективность продаж?</Badge>
      <div style={{height:"4px"}}>&nbsp;</div>

    {/* дальше идет текст основной статьи */}
    <div className="row">
      <div className='col-lg-1 col-md-12'></div>
      <div className='col-lg-10 col-sm-12' style={{background:"white", borderRadius:"3px", minHeight:"100vh", marginBottom:"12px", fontSize:"1.1em", paddingTop:"12px"}}
      dangerouslySetInnerHTML={{ __html: mainText }}>
      </div>
      <div className='col-lg-1 col-md-12'></div>
      <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div>
    </div>
    </>
  )
}

export default {
  shortVersion: <X01_чтоТакоеПродажи_short/>,
  fullVersion: <X01_чтоТакоеПродажи/>,
  blogImage: thumb,
  title: title, 
  shortDescription: shortDescription, 
  link : link
}