import React from "react"
import ГлавнаяСтраница from "../pages/x01_00_ГлавнаяСтраница"
import ВсеПродукты from "../pages/x02_00_ВсеПродукты"
import СозданиеКомМатериаловПоКомпании from "../pages/x02_01_СозданиеКомМатериаловПоКомпании"
import СозданиеКомМатериаловПоСпециалистам from "../pages/x02_02_СозданиеКомМатериаловПоСпециалистам"
import КоммерческаяОбработкаМатериалов from "../pages/x02_03_КоммерческаяОбработкаМатериалов"
import СтруктурированиеИУпорядочиваниеМатериалов from "../pages/x02_04_СтруктурированиеИУпорядочиваниеМатериалов"
import КлассическийПаблишинг from "../pages/x02_05_КлассическийПаблишинг"
import НалаживаниеПотокаМатериалов from "../pages/x02_06_НалаживаниеПотокаМатериалов"
import ГарантииКачестваИПримерыРабот from "../pages/x03_00_ГарантииКачестваИПримерыРабот"
import МетодологияРабот from "../pages/x03_01_МетодологияРабот"
import СтандартыРабот from "../pages/x03_02_СтандартыРабот"
import ПримерыРабот from "../pages/x03_03_ПримерыРабот"
import СхемаРаботы from "../pages/x03_04_СхемаРаботы"
import ПредложениеИЦены from "../pages/x04_00_ПредложениеИЦены"
import БлогАрхив from "../pages/x05_00_БлогАрхив"
import БлогЕдиница from "../pages/x05_01_БлогЕдиница"

class SiteStructure{

    static defaultReactElement = <ГлавнаяСтраница/>

    static entries = {
        //-------------------- главная 
        главнаяСтраница : {
            "url" : "/", 
            "reactComponent" : <ГлавнаяСтраница/>,
            "menuShortDesciption" : "Главная",
            "menuFullDescription" : "на главную страницу сайта",
            "group":"main",
            "title":"Коммерческий Паблишинг",
        },
        //-------------------- сервисы (продукты)
        всеПродукты : {
            "url" : "/allProducts", 
            "reactComponent" : <ВсеПродукты/>,
            "menuShortDesciption" : "📚 Все сервисы (продукты, услуги)",
            "menuFullDescription" : "предоставляемые сервисы (продукты, услуги)",
            "group":"main",
            "title":"Все сервисы",
        },        
                продукты_раздел_созданиеКоммерческихМатериаловПоКомпании : {
                    "url" : "/createCommercialCompanyMaterials", 
                    "reactComponent" : <СозданиеКомМатериаловПоКомпании/>,
                    "menuShortDesciption" : "• 💸 Создание материалов по компании",
                    "menuFullDescription" : "создание и совершенствование коммерческих материалов по компании",
                    "group":"services",
                    "title":"Коммерческие материалы по компании",
                },        
                продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам : {
                    "url" : "/createCommercialSpecialistMaterials", 
                    "reactComponent" : <СозданиеКомМатериаловПоСпециалистам/>,
                    "menuShortDesciption" : "• 🧑‍🤝‍🧑Создание материалов по специалистам",
                    "menuFullDescription" : "создание и совершенствование коммерческих материалов по сотрудникам и специалистам",
                    "group":"services",
                    "title":"Коммерческие материалы по специалистам",
                },        
                продукты_раздел_коммерческаяОбработкаМатериалов : {
                    "url" : "/commercialMaterialProcessing", 
                    "reactComponent" : <КоммерческаяОбработкаМатериалов/>,
                    "menuShortDesciption" : "• 💰 Коммерческая обработка материалов",
                    "menuFullDescription" : "коммерческая и продажная обработка существующих материалов компании",
                    "group":"services",
                    "title":"Коммерческая обработка материалов",
                },        
                продукты_раздел_структурированиеИУпорядочиваниеМатериалов : {
                    "url" : "/commercialMaterialStructuring", 
                    "reactComponent" : <СтруктурированиеИУпорядочиваниеМатериалов/>,
                    "menuShortDesciption" : "• 📑 Структурирование материалов",
                    "menuFullDescription" : "приведение в порядок структуры коммерческих материалов в компании",
                    "group":"services",
                    "title":"Структурирование и упорядочивание материалов",
                },
                продукты_раздел_классическийПаблишинг : {
                    "url" : "/clasicPublishing", 
                    "reactComponent" : <КлассическийПаблишинг/>,
                    "menuShortDesciption" : "• 🧾 Классический паблишинг",
                    "menuFullDescription" : "работы ассоциируемые с копирайтингом и журналистикой",
                    "group":"services",
                    "title":"Классический паблишинг",
                },
                продукты_раздел_налаживаниеПотокаМатериалов : {
                    "url" : "/materialFlow", 
                    "reactComponent" : <НалаживаниеПотокаМатериалов/>,
                    "menuShortDesciption" : "• 💹 Налаживание потока материалов",
                    "menuFullDescription" : "работы связанные с организацией и налаживанием потока коммерческих материалов",
                    "group":"services",
                    "title":"Налаживание потока материалов",
                },        
        //-------------------- гарантии (качество работ)
        гарантииКачестваИПримерыРабот : {
            "url" : "/guarantiesAndExamples", 
            "reactComponent" : <ГарантииКачестваИПримерыРабот/>,
            "menuShortDesciption" : "📖 Сводная информация",
            "menuFullDescription" : "описание методологии, стандартов, схемы, обеспечивающих качество, а также примеры работ",
            "group":"main",
            "title":"Гарантии качества и примеры работ",
        },
                гарантии_раздел_методологияРабот : {
                    "url" : "/methodology", 
                    "reactComponent" : <МетодологияРабот/>,
                    "menuShortDesciption" : "• ✍ Методология",
                    "menuFullDescription" : "описание методологии используемой при выполнении работ",
                    "group":"guarantiesAndExamples",
                    "title":"Методология работ",
                },
                гарантии_раздел_стандартизацияРабот : {
                    "url" : "/standartization", 
                    "reactComponent" : <СтандартыРабот/>,
                    "menuShortDesciption" : "• 🧑‍🎓 Наши стандарты",
                    "menuFullDescription" : "стандарты используемые при выполнении работ",
                    "group":"guarantiesAndExamples",
                    "title":"Стандартизация работ",
                },
                гарантии_раздел_примерыРабот : {
                    "url" : "/beforeAndAfter", 
                    "reactComponent" : <ПримерыРабот/>,
                    "menuShortDesciption" : "• 🪶 Примеры исполнения",
                    "menuFullDescription" : "примеры работ в формате было и стало с обоснованием",
                    "group":"guarantiesAndExamples",
                    "title":"Примеры работ",
                },
                гарантии_раздел_схемаРаботы : {
                    "url" : "/workStages", 
                    "reactComponent" : <СхемаРаботы/>,
                    "menuShortDesciption" : "• 🌐 Схема выполнения работ",
                    "menuFullDescription" : "схема выполнения работ для заказчика, этапы и их содержание с оформлением",
                    "group":"guarantiesAndExamples",
                    "title":"Схема работы",
                },
        //-------------------- коммерческое предложение 
        предложениеИЦены : {
            "url" : "/commercialOfferAndPrices", 
            "reactComponent" : <ПредложениеИЦены/>,
            "menuShortDesciption" : "Предложение и цены",
            "menuFullDescription" : "коммерческое предложение и цены на обслуживание",
            "group":"main",
            "title":"Коммерческое предложение",
        },
        //-------------------- блог 
        блогАрхив : {
            "url" : "/blog", 
            "reactComponent" : <БлогАрхив/>,
            "menuShortDesciption" : "Блог",
            "menuFullDescription" : "публикации и новости компании, более развернуто чем в соц. сетях",
            "group":"main",
            "title":"Блог",
        },
        блогЕдиница : {
            "url" : "/blog/:nameOfEntry", 
            "reactComponent" : <БлогЕдиница/>,
            "menuShortDesciption" : "БлогЕдиница (не для меню)",
            "menuFullDescription" : "...",
            "group":"blog",
            "title":"Запись блога",
        },
    }
}

export default SiteStructure