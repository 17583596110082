import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import SiteStructure from "./routingStructureAndMenu/SiteStructure"
import HeaderMenu from './components/HeaderMenu';
import FooterMain from './components/Footer';

function App() {

  let defineRoute = (what, index) => {
    return <Route key={index}
    path={SiteStructure.entries[what].url} 
    element={SiteStructure.entries[what].reactComponent} 
    />
  }

  function getPages(){
    let pages = []
    for(let el in SiteStructure.entries){
      pages.push(el)
    }
    return pages;
  }

  return (
    <>
    <Router>
    <div className="App">    
    <HeaderMenu/>
    <Container className="mainContent"  id="contentStart">
      
      <div>
      

        {/* здесь разместить компонент с меню */}
        {/*        
        <nav>
          <ul>
            <li>
              <Link to="/">Главная</Link>
            </li>
            <li>
              <Link to="/about">О нас</Link>
            </li>
            <li>
              <Link to="/contact">Контакты</Link>
            </li>
          </ul>
        </nav>
        */
        }
        
        
        <Routes>
          {getPages().map(
              (el, index) => (defineRoute(el, index))              
          )}
          <Route path="*" element={SiteStructure.defaultReactElement} />         
        </Routes>
        
      </div>    
    </Container>               
    </div>
    <FooterMain/>
    </Router>
    </>
  );
}

export default App;
