import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import { useParams } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack'
import Image from 'react-bootstrap/Image'

const link = "текстоваяПодачаВизуальныхОбразов"
const thumb = "blogImages/003.png"
const title = `Текстовая подача визуальных образов`
const shortDescription = `
Как передавать свои мысли, если вы не умеете рисовать: как поставить задачу дизайнеру, как создать эффективный образ? Об этом мы расскажем в этой небольшой публикации.<br>
<br>
Принято считать, что дизайн и текст — это разные составляющие работы, 
и часто компании ориентируются на мнение графического дизайнера, создавая свои коммерческие материалы. Но так ли это работает? Конечно, нет. Основная задача коммерческих текстов — продавать идею, и графика в этом процессе скорее вспомогательный элемент, чем основной.<br>
`

const mainText = `
<p>
в этой небольшой публикации мы расскажем о том как работает передача образов с помощью текста, и почему это важно 
</p>

<p>
дело в том, что часто принято использовать следующий подход к созданию материалов, - сначала создается фирменный стиль, 
после чего дизайнер рисует макет,
если макетов много они группируются и к ним добавляются элементы, 
после чего производится наполнение всего этого текстом.
</p>

<p>
с точки зрения обычного создания материалов корпоративных это работает, иногда,
</p>

<p>
с точки зрения же организации потока материалов для продаж - не работает почти никогда.
</p>

<p>
<b>в чем причина</b>: дело в том что графический дизайн является всего лишь акцентом к передаваемым идеям на аудиторию, 
а идеи можно передать лишь текстом.
</p>

<p>
вот и выходит, что <b>текст при создании материалов является основным, а графический дизайн вторичным</b>.
</p>

<p>
если брать графический дизайн как техническую составляющую продаж, то он работает примерно так:
</p>

<p><i>
мы вышли из дома ночью 
</i></p>

<p>
<i>
Мы вышли из дома тёмной ночью, держа слабый фонарик, который еле проблескивал сквозь тьму.
</i></p>

<p>
видите разницу в подаче?
</p>

<p>
мы создали настроение, выделили идею, и добавили акценты (образы), подчеркивающие эту идею (логика очень простая если уловить суть)
</p>

<p>
идея здесь "про фанарик пронзающий тьму" как раз и требует акцентирования,
и вот это акцентирование и может быть сделано с помощью графики.
(или не сделано, на выбор заказчика, так как можно акцентировать и другие вещи, в примере это сам факт выхода, или то что настала ночь)
</p>

<p>
понимание того как работает этот принцип позволяет в том числе отказаться от согласования графических материалов 
по принципу нравиться и не нравиться и перейти к работе с ними по принципу - выделено акцентом или не акцентировано
</p>

<p>
и именно подобная механика и её понимание является ключем для того чтобы графические материалы заказывать 
у дизайнеров, так как текст написать любой может, как и выделить то, что в нем требуется акцентировать.
</p>

Надеемся это краткое, но объемлющее знание позволит вам лучше ориентироваться в том что такое на самом деле графика и как её воспринимать
в коммерческих материалах (это акцент и настроение, не больше не меньше). 
Спасио за внимание.
`

function X03_текстоваяПодачаВизуальныхОбразов_short(){
  return (
    <>
    короткое от текстовая подача визуальных образов
    </>
  )
}

function X03_текстоваяПодачаВизуальныхОбразов() {
  let { nameOfEntry } = useParams();
  
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>текстовая подача визуальных образов</title>
    </Helmet>      
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>{title}</h1>          
          <div style={{paddingTop:"5px"}}>как передавать свою мысль если вы не умеете рисовать, 
          как поставить задачу дизайнеру, как создать эффективный образ, об этом мы расскажем в этой небольшой публикации</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/* бейджики */}
    <Badge bg="primary" style={{margin:"5px"}}>Как работает композиция?</Badge>
    <Badge bg="secondary" style={{margin:"5px"}}>Что такое образы?</Badge>
    <Badge bg="success" style={{margin:"5px"}}>Как работают акценты?</Badge>
    <div style={{height:"4px"}}>&nbsp;</div>
    
    
    {/* дальше идет текст основной статьи */}
    <div className="row">
      <div className='col-lg-1 col-md-12'></div>
      <div className='col-lg-10 col-sm-12' style={{background:"white", borderRadius:"3px", minHeight:"100vh", marginBottom:"12px", fontSize:"1.1em", paddingTop:"12px"}}
      dangerouslySetInnerHTML={{ __html: mainText }}>
      </div>
      <div className='col-lg-1 col-md-12'></div>
      <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div>
    </div>
    </>
  )
}

export default {
  shortVersion: <X03_текстоваяПодачаВизуальныхОбразов_short/>,
  fullVersion: <X03_текстоваяПодачаВизуальныхОбразов/>,
  blogImage: thumb,
  title: title, 
  shortDescription: shortDescription, 
  link : link
}
