import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

function СхемаРаботы() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.гарантии_раздел_схемаРаботы["title"]}</title>
    </Helmet>     
        {/* это у нас шапка */}
        <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Схема и этапы выполнения работ</h1>          
          <div style={{paddingTop:"5px"}}>хорошая схема работы делает сложное простым, скрывая комплесность за фасадом очевидности и понимания </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/*это у нас стилистическая перебивка */}
    <div className="col-lg-12" style={{margin:"auto", marginBottom:"30px"}}><Image src="/pageImages/schema.png" fluid /></div>



    <div className='row'>

    <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>①</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Обзор истории компании</Card.Title>
          <Card.Text>
            На этом этапе изучается то как компания дошла до текущего момента в плане работы с коммерческими материалами, кто исторически 
            с ними работал и каким образом 
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>②</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Обзор реальных продуктов</Card.Title>
          <Card.Text>
            Не смотря на то что прайс листы компании могут содержать значительные списки, в реальности основными к реализации 
            являются три - шесть позиций. Так как они являются основными генераторами продаж на них делается особенный упор.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>③</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Обзор продуктов которые хотелось бы делать</Card.Title>
          <Card.Text>
            Всегда в любой компании есть продукты которые выглядят перспективными, но они не продаются, выяснение причины 
            почему это происходит на этом этапе 
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>   

      <div className='col-md-6 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>④</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Известность компании</Card.Title>
          <Card.Text>
            Просмотр сведений об известности компании и её настоящих клиентах. У любой компании есть сложившаяся клиентская база 
            на этом этапе надо понять каким образом её можно использовать в пользу коммерции.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>   

      <div className='col-md-6 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑤</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Анализ на месте материалов про реальные продукты</Card.Title>
          <Card.Text>
            В случае если с реальными продуктами у компании всё в порядке, как минимум она эффективно может работать с рынком. 
            Если же этого не происходит - очевидна потеря клиентов с тех каналов в которых компания представлена.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>  

      <div className='col-md-12 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px", color:"darkgreen"}}>⑥</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Решение вопроса о доработке</Card.Title>
          <Card.Text>
          Компания может либо самостоятельно решить вопрос с доработкой на основе рекомендаций (которые мы предоставляем бесплатно), либо решить вопрос о передаче работы по разработке стандартов и базовых материалов нашей организации (это стоит денег). На этом этапе решается вопрос о том, что будет происходить дальше. Дальнейшие работы возможны только при условии заключения контракта с нашей организацией.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">первый день</Card.Footer>
        </Card>
      </div>                   

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑦</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Анализ каналов распространения</Card.Title>
          <Card.Text>
          На этом этапе производится анализ того, как направляются материалы и через какие каналы. Это влияет на то, на какую аудиторию они попадают, и какие тезисы, диспозиции и акценты придётся в них в итоге расставлять.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">второй день</Card.Footer>
        </Card>
      </div> 

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑧</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Сравнение с конкурентами</Card.Title>
          <Card.Text>
          Здесь рассматриваются конкуренты с точки зрения того, какие материалы они производят и с какими каналами работают. Это важно для того, чтобы не изобретать велосипед, и в целом понимать среднюю температуру по больнице на рынке, где работает заказчик.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">второй день</Card.Footer>
        </Card>
      </div>  

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑨</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Поиск возможностей</Card.Title>
          <Card.Text>
          На этом этапе с заказчиком обсуждаются потенциальные возможности, которые видны нам как исполнителям. Потенциальные возможности с высоким рейтингом (аудитория, диспозиция, акценты) также найдут отображение в материалах и стандартах.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">третий день</Card.Footer>
        </Card>
      </div>            

      <div className='col-md-6 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑩</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Составление карты материалов</Card.Title>
          <Card.Text>
            Здесь составляется обзорная карта всех материалов и стандартов, которые будут нами как исполнителями изготовлены, 
            и требования к ним. Данная обзорная карта может стать в том числе приложением к договору.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">четвертый день</Card.Footer>
        </Card>
      </div>                  

      <div className='col-md-6 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑪</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Работа с превышениями</Card.Title>
          <Card.Text>
          Мы считаем, что изготовить базовый комплект материалов удастся менее чем за 100 рабочих часов, в среднем за 15 рабочих дней. Если же это явно недостижимо из-за количества продуктов у заказчика, вопрос решается через исключение избытка или дополнение договора.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">четвертый день</Card.Footer>
        </Card>
      </div>   

      <div className='col-md-12 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px", color:"darkblue"}}>⑫</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Техническое составление материалов</Card.Title>
          <Card.Text>
            На этом этапе материалы физически изготавливаются, под них делаются макеты иллюстратором, веб мастером, 
            работают копирайтеры и коммерсанты. Промежуточные результаты выкладываются в облако, где заказчик может видеть прогресс 
            и динамически вносить в него правки (материалами, всё таки, именно он будет пользоваться). 
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">по четырнадцатый день</Card.Footer>
        </Card>
      </div>                            

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑬</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Составление стандартов</Card.Title>
          <Card.Text>
          В случае если стандарты под материалы уже есть, они дополняют такие материалы по предоставлению заказчику. Если их нет для каких-то материалов, то они создаются. В любом случае вместе с материалами заказчик получит полный комплект стандартов и регламентов о том, как ими пользоваться и как их модифицировать.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">по четырнадцатый день</Card.Footer>
        </Card>
      </div> 

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px"}}>⑭</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Обучение персонала</Card.Title>
          <Card.Text>
          В любом случае происходит обучение персонала, где читается лекция о том, как использовать материалы, как работать с подрядчиками, если это потребуется, и как пользоваться стандартами. Также рассказывается общая теория продаж для общего понимания.
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">четырнадцатый день</Card.Footer>
        </Card>
      </div>  

      <div className='col-md-4 d-flex align-items-stretch'>
        <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }} className="text-center">
        <Card.Header style={{fontSize:"45px", padding:"0px", color:"darkred"}}>⑮</Card.Header>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Title>Сдача работ</Card.Title>
          <Card.Text>
            Стандартная процедура сдачи работ, подписание актов, выяснение претензий, и тд и тп.
            в любом случае заказчик находится на поддержке, поэтому он может обращаться за правками еще в течение месяца 
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">пятнадцатый день</Card.Footer>
        </Card>
      </div>                                     

    </div>

    {/* теперь содержание из блоков */}
    
    {/* ① ② ③ ④ ⑤ ⑥ ⑦ ⑧ ⑨ ⑩
⑪ ⑫ ⑬ ⑭ ⑮ ⑯ ⑰ ⑱ ⑲ ⑳ */}

    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>
    </>
  )
}

export default СхемаРаботы