import React from 'react';
import { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import ВсеПродукты from "../pages/x02_00_ВсеПродукты"

function FooterMain() {
  return (
    <div className="mainFooter" style={{borderTop:"2px solid lightgray"}}>
    <Container>
    <div>
    Проект реализуется ООО "ПРАГМАТИК" ИНН 9722079366, 111024, г. Москва, улица 2-Я КАБЕЛЬНАЯ, Д. 2, СТР. 3, ПОМЕЩ. 1/1<br/>
    для подготовки контента к запуску специализированных агрегаторов компаний 
    </div>    
    <div>&nbsp;</div>
    <div className="row">
      <div className="col-md-4" style={{fontWeight:"700"}}>
        УСЛУГИ И СЕРВИСЫ
        <hr style={{width:"50%"}}></hr>
        <NavDropdown.Item  as={Link} to={SiteStructure.entries.всеПродукты.url}>{SiteStructure.entries.всеПродукты.title}</NavDropdown.Item>
              <NavDropdown.Divider />              
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоКомпании.url}>{SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоКомпании.title}</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам.url}>{SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам.title}</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_коммерческаяОбработкаМатериалов.url}>{SiteStructure.entries.продукты_раздел_коммерческаяОбработкаМатериалов.title}</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_структурированиеИУпорядочиваниеМатериалов.url}>{SiteStructure.entries.продукты_раздел_структурированиеИУпорядочиваниеМатериалов.title}</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_классическийПаблишинг.url}>{SiteStructure.entries.продукты_раздел_классическийПаблишинг.title}</NavDropdown.Item>
              <NavDropdown.Item  as={Link} to={SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов.url}>{SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов.title}</NavDropdown.Item>     
      </div>



      <div className="col-md-4" style={{fontWeight:"700"}}>
        ГАРАНТИИ И ПРИМЕРЫ
        <hr style={{width:"50%"}}></hr>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантииКачестваИПримерыРабот.url}>{SiteStructure.entries.гарантииКачестваИПримерыРабот.title}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}>{SiteStructure.entries.гарантии_раздел_методологияРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}>{SiteStructure.entries.гарантии_раздел_стандартизацияРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}>{SiteStructure.entries.гарантии_раздел_примерыРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}>{SiteStructure.entries.гарантии_раздел_схемаРаботы.title}</NavDropdown.Item>            
      </div>
      <div className="col-md-2" style={{fontWeight:"700"}}>
        ПУБЛИКАЦИИ 
        <hr style={{width:"50%"}}></hr>
        <NavDropdown.Item as={Link} to={SiteStructure.entries.блогАрхив.url}>{SiteStructure.entries.блогАрхив.title}</NavDropdown.Item>
      </div>
    </div>
    </Container>
    <div>&nbsp;</div>
    </div>
  )
}

export default FooterMain