import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
// статьи из блога:
import ЧтоТакоеПродажи from "../blogEntries/x01_чтоТакоеПродажи"
import КакТекстПродаёт from '../blogEntries/x02_какТекстПродает'
import ТекстоваяПодачаВизуальныхОбразов from '../blogEntries/X03_текстоваяПодачаВизуальныхОбразов'
import { Container, Row } from 'react-bootstrap';

/*
  отрисовываем записи здесь 
  массивы делать не нужно слишком записей все равно мало 
  ---
  рисуем лесенкою 
  и не забываем 
  вставить шапку с описателем 

*/

function БлогАрхив() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.блогАрхив["title"]}</title>
    </Helmet>     
    
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Наши публикации</h1>          
          <div style={{paddingTop:"5px"}}>здесь мы размещаем публикации связанные с продажами и коммерческим паблишингом</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>

    {/* это у нас сетка с публикациями */}

    {/** блок для одной публикации : начало */}
    <Row  as={Link} to={ЧтоТакоеПродажи.link}>
      <div className='col-lg-2 order-1 order-lg-1'></div>
      <div className='col-lg-4 col-md-6 order-lg-2 order-sm-1 order-1'>
        <h3>{ЧтоТакоеПродажи.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: ЧтоТакоеПродажи.shortDescription }}></div>
        <div style={{textAlign:"right", fontWeight:"bolder"}}>..продолжить чтение</div>
      </div>
      <div className='col-lg-4 col-md-6 order-lg-3 order-sm-2 order-2'>
      <Card style={{ width: '100%'}}>
      <Card.Img variant="top" src={ЧтоТакоеПродажи.blogImage} />
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Как работают продажи?</ListGroup.Item>
        <ListGroup.Item>Почему одно продает лучше другого?</ListGroup.Item>
        <ListGroup.Item>Как оценить эффективность продаж?</ListGroup.Item>
      </ListGroup>
    </Card>
      </div>
      <div className='col-lg-2 order-lg-4 order-md-4'></div>
    </Row>
    {/** блок для одной публикации : конец */}

    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>

    {/** блок для одной публикации : начало */}
    <Row  as={Link} to={КакТекстПродаёт.link}>
      <div className='col-lg-2 order-lg-1'></div>
      <div className='col-lg-4 col-md-6 order-lg-3'>
        <h3>{КакТекстПродаёт.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: КакТекстПродаёт.shortDescription }}></div>
        <div style={{textAlign:"right", fontWeight:"bolder"}}>..продолжить чтение</div>
      </div>
      <div className='col-lg-4 col-md-6 order-lg-2'>
      <Card style={{ width: '100%'}}>
      <Card.Img variant="top" src={КакТекстПродаёт.blogImage} />
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Как текст может продавать?</ListGroup.Item>
        <ListGroup.Item>Что такое идея продаж?</ListGroup.Item>
        <ListGroup.Item>Что такое аудитория текста?</ListGroup.Item>
      </ListGroup>
    </Card>
      </div>
      <div className='col-lg-2 col-md-6 order-lg-4'></div>
    </Row>
    {/** блок для одной публикации : конец */}


    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>

    {/** блок для одной публикации : начало */}
    <Row  as={Link} to={ТекстоваяПодачаВизуальныхОбразов.link}>
      <div className='col-lg-2 order-1 order-lg-1'></div>
      <div className='col-lg-4 col-md-6 order-lg-2 order-sm-1 order-1'>
        <h3>{ТекстоваяПодачаВизуальныхОбразов.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: ТекстоваяПодачаВизуальныхОбразов.shortDescription }}></div>
        <div style={{textAlign:"right", fontWeight:"bolder"}}>..продолжить чтение</div>
      </div>
      <div className='col-lg-4 col-md-6 order-lg-3 order-sm-2 order-2'>
      <Card style={{ width: '100%'}}>
      <Card.Img variant="top" src={ТекстоваяПодачаВизуальныхОбразов.blogImage} />
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Как работает композиция?</ListGroup.Item>
        <ListGroup.Item>Что такое образы?</ListGroup.Item>
        <ListGroup.Item>Как работают акценты?</ListGroup.Item>
      </ListGroup>
    </Card>
      </div>
      <div className='col-lg-2 order-lg-4 order-md-4'></div>
    </Row>
    {/** блок для одной публикации : конец */}


    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>

    </>
  )
}

export default БлогАрхив