import React from 'react';
import { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import ВсеПродукты from "../pages/x02_00_ВсеПродукты"


function HeaderMenu() {

  const location = useLocation()
  useEffect(() => {
      const element = document.getElementById('contentStart');
      if (element) {
        window.scrollTo(0, 0)
        //element.scrollIntoView({ behavior: 'smooth' });
      }
  }, [location])

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleClickMenuItem = () => setIsMenuOpen(false);
  
  // закрытие меню от каждого клика по чему угодно на мобиле:
  const [expanded, setExpanded] = useState(false)
  const toggleMenuA = () => {
    const isMobile = window.innerWidth <= 1000
    if(isMobile) setExpanded(!expanded)
  }

  return (
    <>        
      <Navbar expanded={expanded} expand="lg" className="bg-body-tertiary mainMenu" fixed="top" style={{backgroundColor:"white !important"}}>
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={`${process.env.PUBLIC_URL}/pero.png`} style={{width:"32px"}} alt="Описание" /> 
        <span style={{position:"relative", paddingTop:"5px"}}>
          <div style={{position:"absolute", bottom:"-7px", left:"6px", color:"#1191a1", fontSize:"9px"}}>коммерческий</div>
          <span>&nbsp;ПАБЛИШИНГ &nbsp;</span>
        </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenuA} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            <NavDropdown title="Услуги и Сервисы" id="basic-nav-dropdown" style={{marginRight:"20px", paddingBottom:"7px"}}>   

              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.всеПродукты.url}>{SiteStructure.entries.всеПродукты.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Divider />              
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоКомпании.url}>{SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоКомпании.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам.url}>{SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_коммерческаяОбработкаМатериалов.url}>{SiteStructure.entries.продукты_раздел_коммерческаяОбработкаМатериалов.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_структурированиеИУпорядочиваниеМатериалов.url}>{SiteStructure.entries.продукты_раздел_структурированиеИУпорядочиваниеМатериалов.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_классическийПаблишинг.url}>{SiteStructure.entries.продукты_раздел_классическийПаблишинг.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов.url}>{SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов.menuShortDesciption}</NavDropdown.Item>     

            </NavDropdown>

            <NavDropdown title="Гарантии и Примеры" id="basic-nav-dropdown" style={{marginRight:"20px", paddingBottom:"7px"}}>                          
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.гарантииКачестваИПримерыРабот.url}>{SiteStructure.entries.гарантииКачестваИПримерыРабот.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}>{SiteStructure.entries.гарантии_раздел_методологияРабот.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}>{SiteStructure.entries.гарантии_раздел_стандартизацияРабот.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}>{SiteStructure.entries.гарантии_раздел_примерыРабот.menuShortDesciption}</NavDropdown.Item>
              <NavDropdown.Item  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}>{SiteStructure.entries.гарантии_раздел_схемаРаботы.menuShortDesciption}</NavDropdown.Item>            

            </NavDropdown>

            <Nav.Link  onClick={toggleMenuA} as={Link} to={SiteStructure.entries.блогАрхив.url}>{SiteStructure.entries.блогАрхив.menuShortDesciption}</Nav.Link>
              
          </Nav>

          <div className="d-lg-none">&nbsp;</div>

          <Navbar.Collapse style={{paddingLeft:"10px", paddingRight:"10px"}} className="justify-content-end">
            <Navbar.Text>
            📱<b>+7 901 684 4031</b>&nbsp;
            </Navbar.Text>
          </Navbar.Collapse>

          <a href="/cats/catWebOpt.pdf" target="_blank" rel="noopener noreferrer">
          <Button 
            style={{backgroundColor:"#1191a1", paddingBottom:"8px"}} 
            type="submit" 
            className="ms-auto d-inline-block"
          >
            КОММЕРЧЕСКОЕ
          </Button>
          </a>

        </Navbar.Collapse>
      </Container>
    </Navbar>

    </>
  );
}

export default HeaderMenu;
