import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import { useParams } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack'
import Image from 'react-bootstrap/Image'

const link = "какТекстПродаёт"
const thumb = "blogImages/002.png"
const title = `Как текст продаёт`
const shortDescription = `
Здесь мы расскажем о том, каким должен быть текст, чтобы он эффективно коммуницировал с читателем и был способен продавать.<br>
<br>
Для того чтобы текст продавал, он должен иметь чётко установленную композицию и структуру, быть направленным на определённую аудиторию, покрывать только её запросы и содержать одну единственную идею продаж.<br>
`
const mainText = `
<p>
В этой небольшой публикации мы расскажем о том, что должно быть в тексте для того чтобы он эффективно выполнял функцию продаж
</p>

<p>
Сразу отметим, что для коммерческой организации выпускать контент который не продаёт достаточно не эффективно, 
то есть любой текст который издает коммерческая организация должен быть нацелен на то чтобы продать какую то отдельную идею
</p>

<p>
Так даже карта со схемой проезда должна в идеале продавать идею о том "как удобно к нам добраться", 
а описание биографии директора "как нам повезло с тем что такой директор работает у нас".
</p>

<p>
примеры можно продолжать бесконечно.
</p>

<p>
но это основная идея в том как организовывать продажи через контент.
</p>

<p>
вторая идея заключается в том, что если текст продает, то он как минимум содержит в себе два правила 
эффективных продаж, то есть он: 1. имеет в себе одну идею, 2. направлен на одну аудиторию
</p>

<p>
как это работает мы писали в одной из публикаций.
</p>

<p>
с другой стороны в случае если каждый текст содержит в себе коммерческую информацию не будет ли слишком 
ярко и пошло для читателя такие тексты смотреть, да будет, 
все что касается не чисто коммерческих материалов требует для коммерческих идей некоторой маскировки.
</p>

<p>
Существуют разные способы того как правильно закладывать коммерческие идеи в <b>любые тексты</b>,
здесь мы расскажем о самом простом и очевидном варианте
</p>

<p>
Например мы издаем статью от организации о том как правильно питаться летом овощами, 
а тематика нашей организации это спортивное питание.
</p>

<p>
хочется и продать, и при этом сам текст про овощи не испортить, 
и очевидно что овощи это не спортивное питание.
</p>

<p>
однако мы их можем связать простой формулой: <b>было - стало</b>
</p>

<p>
То есть мы можем задать повествованию инерцию, которая в итоге одну идею
переведет эффективно в другую.
</p>

<p>
<b>первая идея</b>: овощи есть летом полезно
</p>

<p>
<b>переход</b>: обратите внимание что вообще есть полезные вещи полезно
</p>

<p>
<b>вторая идея коммерческая</b>: спортивное питание, может быть не менее полезным чем овощи
</p>

<p>
Вот таким простым переводом не коммерческий текст станет коммерческим и его можно будет 
продвигать в социальных сетях уже как фактически полноценный коммерческий материал.
</p>

<p>
Остается лишь решить вопрос со стилистикой и правильным оформлением как первой идеи так и второй 
так и перехода.
</p>

<p>
Надеемся это краткое, но объемлющее знание позволит вам не допускать основных ошибок при создании текстов, то есть не выпускать 
контент, не ориентированный на коммерческие идеи, и в том числе не создавать текст который пошлит коммерцией. Спасибо за внимание.
</p>
`

function X02_какТекстПродаёт_short(){
  return (
    <>
    короткое как текст продаёт  
    </>
  )
}

function X02_какТекстПродаёт() {
  let { nameOfEntry } = useParams();
  
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>как текст продаёт</title>
    </Helmet>      
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>{title}</h1>          
          <div style={{paddingTop:"5px"}}>здесь мы расскажем о том, каким должен быть текст для того чтобы он настолько эффективно коммуницировал с читающим, 
          что был в состоянии продавать</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/* бейджики */}
    <Badge bg="primary" style={{margin:"5px"}}>Как текст может продавать?</Badge>
    <Badge bg="secondary" style={{margin:"5px"}}>Что такое идея продаж?</Badge>
    <Badge bg="success" style={{margin:"5px"}}>Что такое аудитория текста?</Badge>
    <div style={{height:"4px"}}>&nbsp;</div>
    
    {/* дальше идет текст основной статьи */}
    <div className="row">
      <div className='col-lg-1 col-md-12'></div>
      <div className='col-lg-10 col-sm-12' style={{background:"white", borderRadius:"3px", minHeight:"100vh", marginBottom:"12px", fontSize:"1.1em", paddingTop:"12px"}}
      dangerouslySetInnerHTML={{ __html: mainText }}>
      </div>
      <div className='col-lg-1 col-md-12'></div>
      <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div>
    </div>
    </>
  )
}

export default {
  shortVersion: <X02_какТекстПродаёт_short/>,
  fullVersion: <X02_какТекстПродаёт/>,
  blogImage: thumb,
  title: title, 
  shortDescription: shortDescription, 
  link : link
}