import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'

function ВсеПродукты() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.всеПродукты["title"]}</title>
    </Helmet>
        {/* это у нас шапка */}
        <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Все Сервисы Нашей Компании</h1>          
          <div style={{paddingTop:"5px"}}>за недели мы делаем реальными процессы которые не могут наладить годами</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div style={{textAlign:"center", fontSize:"32px"}}>~</div>

    <div className='row'>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }} as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоКомпании.url}>
      <Card.Img variant="top" src="prodImages/001.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Создание Материалов по Компании</Card.Title>
        <Card.Text>
        Мы создадим полный, качественный и воспроизводимый комплект коммерческих
         материалов, описывающих компанию и её деятельность. 
         Руководству и собственникам не придётся больше переживать о том, 
         что под какой-то случай нужных материалов не хватает.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Привлекательное описание компании</ListGroup.Item>
        <ListGroup.Item>Выделение идеи деятельности компании</ListGroup.Item>
        <ListGroup.Item>Выделение продуктов и их коммерческое описание</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.продукты_раздел_созданиеКоммерческихМатериаловПоСпециалистам.url}>
      <Card.Img variant="top" src="prodImages/002.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Материалы по Специалистам</Card.Title>
        <Card.Text>
        Мы представим ваших специалистов как в виде биографий, так и в виде ремарок к продуктам и интервью. 
        Всё будет говорить о том, какие они профессионалы, 
        при этом мы будем стараться абстрагироваться от личности отдельного сотрудника, 
        чтобы вы могли бы его в нужный момент заменить, не меняя описания.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Профили специалистов</ListGroup.Item>
        <ListGroup.Item>Комментарии, ремарки</ListGroup.Item>
        <ListGroup.Item>Коммерческое представление (УТП, выгоды)</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.продукты_раздел_коммерческаяОбработкаМатериалов.url}>
      <Card.Img variant="top" src="prodImages/003.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Коммерческая Обработка Материалов</Card.Title>
        <Card.Text>
        Мы проработаем имеющиеся материалы, чтобы определить возможность включения в них продажных и коммерческих нарративов, и изменим модель повествования там, где это возможно. Выделим возможные пропущенные модели продаж.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Каждый материал в компании будет иметь смысл и идею</ListGroup.Item>
        <ListGroup.Item>Материалы будут систематизированы по аудиторям</ListGroup.Item>
        <ListGroup.Item>Коммерчески пассивные материалы будут исключены из оборота</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
      <div>&nbsp;</div>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.продукты_раздел_структурированиеИУпорядочиваниеМатериалов.url}>
      <Card.Img variant="top" src="prodImages/004.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Структурирование Материалов</Card.Title>
        <Card.Text>
        Мы разберём и систематизируем текущие материалы. В случае если компания планирует запускать новые активности, дадим рекомендации о том, какими материалами нужно будет дополнить комплект.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Список материалов с отличительными особенностями</ListGroup.Item>
        <ListGroup.Item>Стандарты по материалам и коммерции в целом</ListGroup.Item>
        <ListGroup.Item>Указание на нехватающие материалы</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.продукты_раздел_классическийПаблишинг.url}>
      <Card.Img variant="top" src="prodImages/005.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Классический Паблишинг</Card.Title>
        <Card.Text>
        Мы создаём не просто публикации, а экспертный комплексный продукт, представляющий компанию на уровне лучших на рынке. На вас будут ссылаться и цитировать.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Экспертное раскрытие темы на уровне кандидатской</ListGroup.Item>
        <ListGroup.Item>Стилистика под аудиторию заявленных СМИ</ListGroup.Item>
        <ListGroup.Item>Включение коммерческих нарративов</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
      <div className='col-md-4 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов.url}>
      <Card.Img variant="top" src="prodImages/006.jpg" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Налаживание Потока Материалов</Card.Title>
        <Card.Text>
        Мы создадим эффективную систему создания и распространения материалов компании по доступным каналам, исходя из бюджета и намерений заказчика. Руководству и собственникам больше не придётся переживать о том, что об их компании никто не знает или она слабо представлена.
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>Адекватный контент план</ListGroup.Item>
        <ListGroup.Item>Стоимостные характеристики и отдача</ListGroup.Item>
        <ListGroup.Item>Аналитика по конкурентам</ListGroup.Item>
      </ListGroup>
    </Card>        
      </div>
    </div>

    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>

    </>
  )
}

export default ВсеПродукты