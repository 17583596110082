import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import { Row } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup'

function ГлавнаяСтраница() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.главнаяСтраница["title"]}</title>
    </Helmet>
<div style={{maxHeight:"10px"}}>&nbsp;</div>
{/* головной слайдер */}
<Card className="text-white position-relative"
    style={{
      background: "url(mainPage/main.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      minHeight:"600px"
    }}
  >
  {/*Card.Img className="img-fluid" src="prodImages/001.jpg" alt="" />*/}
  {/*<Card.Img className="img-fluid" src="mainPage/слайдерГлавнойБезТекста.png" alt="" />*/}
  <div className="card-overlay"></div>
  <Card.ImgOverlay 
    style={{
      backgroundImage: "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.3), rgba(0,0,0,0.6))",
      backgroundSize: "200% auto",
      backgroundPosition: "center center",
      transition: "background-position 0.5s ease-out",
    }}    
    >
    <Card.Title style={{fontSize:"32px", textAlign:"left", textShadow:"-1px 1px 1px rgba(255, 255, 255, 0.5)"}}>Рады видеть Вас на нашем сайте <br></br><br></br>Мы эксперты в <span style={{color:"rgb(160, 244, 255)"}}>продажах</span> и в создании<br></br>
top tier <span style={{color:"rgb(160, 244, 255)"}}>коммерческих<br></br>
материалов</span>, обеспечивающих <br></br>такие
продажи.</Card.Title>
    <Card.Body style={{textAlign:"left", fontSize:"30px"}}>&bull; &bull; &bull;</Card.Body>
  </Card.ImgOverlay>
  </Card>

    <hr></hr>
    <hr style={{width:"50%", margin:"auto"}}></hr>
    <div>&nbsp;</div> 

<Row>
<div className='col-lg-6 col-md-6 order-lg-1 order-sm-2 order-2' style={{}}>
      <h4>
      Вы как компания работаете с рынком используя коммерческие материалы,<br></br>
      которые являются по сути частью ваших<br></br>
      ПРОДАЖ.<br></br>
      </h4>
      <hr style={{width:"50%", textAlign:"left"}}></hr>
      <h4>
      Мы профессионально изготавливаем такие материалы.
      </h4>
      <hr style={{width:"50%", textAlign:"left"}}></hr>
      <h4>
      У нас есть потребность<br></br>
      <br></br>
      в адекватном заказчике, предпочитающем работу с профессионалами и желающим
      увидеть результаты сразу, а не через месяцы
      или годы.
      </h4>
      <hr style={{width:"50%", textAlign:"left"}}></hr>
      <h4>
      Предлагаем объединить желания
      и дать нам возможность
      заняться вами.
      </h4>
      <hr style={{width:"50%", textAlign:"left"}}></hr>
      <h4>
      Просто позвоните: +7 901 684 4031
      </h4>
    </div>
    <div className='col-lg-6 col-md-6 order-lg-2 order-sm-1 order-1' style={{position:"relative"}}>
    <h1 style={{position:"absolute", top:15, left:25, color:"darkgray"}}>ИДЕЯ ПРОСТА</h1>
    <Image src="mainPage/ручкаБезЗадника.png" fluid />
    </div>
</Row>

{/* приглашение полазить по сайту */}
{/* это у нас шапка */}
<div>&nbsp;</div>
<hr></hr>
<div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"0px", paddingBottom:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Что можно о нас посмотреть</h1>          
        </div>
    </div>

<div className='row'>
      <div className='col-md-6 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }} as={Link} to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}>
      <Card.Img variant="top" src="pageImages/method.png" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Методология нашей работы</Card.Title>
        <Card.Text>
        Здесь рассказывается о методологии, которую мы используем, и о том, как она обеспечивает качество выполняемых работ.
        </Card.Text>
      </Card.Body>
    </Card>        
      </div>
      <div className='col-md-6 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}>
      <Card.Img variant="top" src="pageImages/standards.png" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Наши стандарты</Card.Title>
        <Card.Text>
        Здесь рассказывается об используемых нами стандартах, объясняется, почему это важно, и говорится о том, почему это гарантирует вам почти стопроцентную точность результатов.
        </Card.Text>
      </Card.Body>
    </Card>        
      </div>
      <div>&nbsp;</div>
      <div className='col-md-6 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }} as={Link} to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}>
      <Card.Img variant="top" src="pageImages/11.png" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Примеры наших работ</Card.Title>
        <Card.Text>
        Здесь можно посмотреть, как мы выполняем свою работу, с точки зрения того, как мы мыслим и какие методы используем для формирования материалов.
        </Card.Text>
      </Card.Body>
    </Card>        
      </div>
      <div className='col-md-6 d-flex align-items-stretch'>        
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}  as={Link} to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}>
      <Card.Img variant="top" src="pageImages/schema.png" />
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title>Схема нашей работы</Card.Title>
        <Card.Text>
        Здесь можно посмотреть схему нашей работы. Она достаточно проста для понимания и чётко отражает всё, что происходит с заказчиком во время работы.
        </Card.Text>
      </Card.Body>
    </Card>        
      </div>
    </div>

    <div>&nbsp;</div>

{/* цитата для понтов */}
<Card style={{border:"0px solid white", background:"none", textAlign:"center"}}>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            Великие продавцы - это строители отношений, которые обеспечивают ценность и помогают своим клиентам побеждать.{' '}
          </p>
          <footer className="blockquote-footer">
          Джеффри Гитомер
          </footer>
        </blockquote>
      </Card.Body>
    </Card>

<div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>

    </>
  )
}

export default ГлавнаяСтраница
