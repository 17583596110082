import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'

function ГарантииКачестваИПримерыРабот() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.гарантииКачестваИПримерыРабот["title"]}</title>
    </Helmet>      
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Гарантии качества и примеры работы</h1>          
          <div style={{paddingTop:"5px"}}>гарантия качества обеспечивается методологией, стандартами и схемой работы, технический процессинг работы выложен в разделе примеров работы</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div>&nbsp;</div>
    <div className='row'>

    <div className='col-md-6'>
    <Card className="bg-dark text-white position-relative" as={Link} to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}>
      <Card.Img className="img-fluid" src="/pageImages/method.png" alt="Card image" />
      <div className="card-overlay"></div>
      <Card.ImgOverlay style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
        <Card.Title style={{fontSize:"30px"}}>МЕТОДОЛОГИЯ</Card.Title>
      </Card.ImgOverlay>
    </Card>
    </div>
    <div className='col-md-6'>
    <div>Обеспечивается сочетанием таких компонентов как: <br></br>Понимание, Материалы и Поддержка</div>
    <div>&nbsp;</div>
    <div>Работа компании построена на сочетании: <br></br>Стандартов и Шаблонов(драфтов)</div>
    <div>&nbsp;</div>
    <div></div>
    <Link to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}><b>Подробнее в разделе "Методология"</b></Link>
    </div>    

    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div>&nbsp;</div>

    <div className='col-md-6'>
    <Card className="bg-dark text-white position-relative" as={Link} to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}>
      <Card.Img className="img-fluid" src="/pageImages/standards.png" alt="Card image" />
      <div className="card-overlay"></div>
      <Card.ImgOverlay style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
        <Card.Title style={{fontSize:"30px"}}>СТАНДАРТЫ</Card.Title>
      </Card.ImgOverlay>
    </Card>
    </div>
    <div className='col-md-6'>
      работа компании в широком смысле слова построена на стандартизации процессов
      <div>&nbsp;</div>
      на текущий момент стандартизированы 16 типов материалов
      <div>&nbsp;</div>
      при этом практически каждый стандарт включает в себя:<br></br>
      требования к основной цели и задаче, требования к разделам документа, требования к изображениям, требования к тексту, 
      требования к оформлению, технические поля для разделов 
    <div>&nbsp;</div>
    <Link to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}><b>Подробнее в разделе "Стандарты"</b></Link>
    </div>    

    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div>&nbsp;</div>

    <div className='col-md-6'>
    <Card className="bg-dark text-white position-relative" as={Link} to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}>
      <Card.Img className="img-fluid" src="/pageImages/11.png" alt="Card image" />
      <div className="card-overlay"></div>
      <Card.ImgOverlay style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
        <Card.Title style={{fontSize:"30px"}}>ПРИМЕРЫ РАБОТЫ</Card.Title>
      </Card.ImgOverlay>
    </Card>
    </div>
    <div className='col-md-6'>
      лучше один раз увидеть чем сто раз услышать 
      <div>&nbsp;</div>
      в этом разделе мы даем полное понимание того как поэтапно строиться работа по обработке материалов 
      <div>&nbsp;</div>
      взят произвольный семпл для исключения субьективной оценки материалов
    <div>&nbsp;</div>
    <Link to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}><b>Подробнее в разделе "Примеры работы"</b></Link>
    </div> 

    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div>&nbsp;</div>

    <div className='col-md-6'>
    <Card className="bg-dark text-white position-relative" as={Link} to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}>
      <Card.Img className="img-fluid" src="/pageImages/schema.png" alt="Card image" />
      <div className="card-overlay"></div>
      <Card.ImgOverlay style={{backgroundColor:"rgba(0,0,0,0.5)"}}>
        <Card.Title style={{fontSize:"30px"}}>СХЕМА РАБОТЫ</Card.Title>
      </Card.ImgOverlay>
    </Card>
    </div>
    <div className='col-md-6'>
      представлена схема работы из 15 шагов
      <div>&nbsp;</div>
      Обзор истории компании, обзор реальных продуктов, обзор потенциальных продуктов, анализ известности, анализ материалов по реальным продуктам, 
      решение вопроса о доработке, анализ каналов распространения, сравнение с конкурентами, поиск возможностей, 
      составление карты материалов, работа с превышениями, техническое составление материалов, составление стандартов, 
      обучение персонала, сдача работы 
    <div>&nbsp;</div>
    <Link to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}><b>Подробнее в разделе "Схема работы"</b></Link>
    </div> 


    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>
    <div>&nbsp;</div>




    </div>

{/*
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантииКачестваИПримерыРабот.url}>{SiteStructure.entries.гарантииКачестваИПримерыРабот.title}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}>{SiteStructure.entries.гарантии_раздел_методологияРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}>{SiteStructure.entries.гарантии_раздел_стандартизацияРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_примерыРабот.url}>{SiteStructure.entries.гарантии_раздел_примерыРабот.title}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}>{SiteStructure.entries.гарантии_раздел_схемаРаботы.title}</NavDropdown.Item>  
*/}
    

    <div>&nbsp;</div> 
      <div>&nbsp;</div> 

      <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 


    </>
  )
}

export default ГарантииКачестваИПримерыРабот