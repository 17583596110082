import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import { useParams } from 'react-router-dom'
import ГлавнаяСтраница from "../pages/x01_00_ГлавнаяСтраница"

// статьи из блога:
import ЧтоТакоеПродажи from "../blogEntries/x01_чтоТакоеПродажи"
import КакТекстПродаёт from '../blogEntries/x02_какТекстПродает'
import ТекстоваяПодачаВизуальныхОбразов from '../blogEntries/X03_текстоваяПодачаВизуальныхОбразов'

/* 
по сути это наш микророутер 
можно было бы его сделать по рекомендации бота - то есть парсить папку 
и загружать компоненты автоматом - но комон - у нас компонентов то этих 
раз два и обчелся 
*/

function БлогЕдиница() {
    let { nameOfEntry } = useParams()

    const createComponentByName = (componentName) => {
      switch (componentName) {
        case ЧтоТакоеПродажи.link:                      return ЧтоТакоеПродажи.fullVersion
        case КакТекстПродаёт.link:                      return КакТекстПродаёт.fullVersion
        case ТекстоваяПодачаВизуальныхОбразов.link:     return ТекстоваяПодачаВизуальныхОбразов.fullVersion
        default:                                        return <ГлавнаяСтраница/>
      }
    }
    return createComponentByName(nameOfEntry)
}

export default БлогЕдиница