import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Image from 'react-bootstrap/Image'

function ПримерыРабот() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.гарантии_раздел_примерыРабот["title"]}</title>
    </Helmet>      
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Типовой пример работы</h1>          
          <div style={{paddingTop:"5px"}}>
            
          Лучше один раз увидеть, чем сто раз услышать. Разберём проблемный материал и произведём его модификацию до адекватного уровня, покажем в процессе обоснование производимых модификаций. А чтобы никого не обидеть, возьмём произвольный материал из интернета, совсем уж не связанный с тематикой. Предупреждаем, текст раздела будет длинным, но мы хотим показать всю работу, так сказать, «изнутри».

          </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"12px"}}></hr>

    
    <div className='row'>
      <div className='col-md-12'>
        <noindex>
        <div style={{ padding:"10px", border:"1px solid lightgray"}}>
          <p>
          В интернет-магазине ... представлены сотни моделей, каждая из которых обязательно найдет своего покупателя:
          </p>
          <p>
          Детские диваны производятся из безопасных (гипоаллергенных) материалов и не имеют острых углов – тех самых, о которые можно пораниться. Некоторые модели с раннего возраста приучают детей к порядку – в них есть ящики для хранения игрушек и постельного белья.
          </p>
          <p>
          Классические прямые диваны подходят для любых комнат, даже небольших кухонь. Они занимают мало места и раскладываются, если это предусмотрено производителем.
          </p>
          <p>
          Угловые считаются самыми удобными. Они полностью занимают угол комнаты, а при раскладывании превращаются в большую кровать, на которой могут поместиться 2-4 человека.
          Модульные. Состоят из нескольких частей, которые можно передвигать и всячески комбинировать. При необходимости модули заменяют собой кресла, стулья и пуфики.
          </p>
          <p>
          Мы предлагаем современные диваны из натуральных и качественных искусственных материалов, с разными механизмами трансформации, вариантами наполнения и жесткости. Действуют гарантии производителей и быстрая доставка по России.
          </p>
          <p>
          Купить диван можно двумя способами: заказать на сайте или по номеру .... Большинство товаров есть в наличии, а скорость доставки и цены вас приятно удивят.
          </p>
        </div>
        </noindex>
      </div>
    </div>

    <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>Первый шаг модификации - выделение проблемных зон </h6>          
          <div style={{paddingTop:"5px"}}>здесь мы ищем не соответствие стандартам и общим принципам продаж 
          </div>
          <div style={{paddingTop:"5px"}}>~
          </div>
    </div>

    <div className='col-md-12'style={{textAlign:"justify", paddingTop:"20px"}}>
      <div className='row'>
        <div className='col-lg-2'></div>
        <div className='col-lg-8'>
        в тексте сразу наблюдаются две базовые ошибки:<br></br>
        &bull; не указана аудитория для которой ведеться продажа <br></br>
        &bull; намешаны в кучу предложения и связанные с ними идеи <br></br>
        <br></br>
        более того присуствуют сразу скопом технические ошибки, которые мешают продавать:<br></br>
        <br></br>
        &bull; нет эмоционального наполнения (мы же все таки продаем)<br></br>
        &bull; нет драйверов действия (не понятно, что надо делать тому, кто прочитал материал (удивляться от цен?))<br></br>
        &bull; нет акцентов (материал не выделяет вообще ничего)<br></br>
        &bull; нет отслоения от конкурентов (этот текст можно наложить на любой интернет магазин в мире)<br></br>
        <br></br>
        ну и стилистически это просто достаточно большой образец текста, который долго и неудобно читать 
        </div>
        <div className='col-lg-2'></div>
    </div>
  </div>

  <hr></hr>

  <div className='col-md-12'style={{textAlign:"justify", paddingTop:"0px"}}>
      <div className='row'>
        <div className='col-lg-2'></div>
        <div className='col-lg-8'>
        произведем модификацию <br></br>
        исходя из того что мы выделили<br></br> 
        (мы могли бы выбрать и другие направляющие)<br></br>
        <br></br>
        (-1-)<br></br>
        с помощью диспозиции определим аудиторию<br></br>
        например наш кейс это семья выбирающая мебель для дома <br></br>
        она выбирает мебель уже какое то время <br></br>
        выбор большой <br></br>
        магазинов много <br></br>
        смотреть надо материалов много <br></br>
        стресс <br></br>
        <br></br>
        (-2-)<br></br>
        основной идеей продажи будет идея о том - что <br></br>
        в интернет магазине есть все для того чтобы обставить жилое помещение <br></br>
        это позволит нам сохранить перечисление (в рамках одной идеи это допустимо) <br></br>
        <br></br>
        (-3-)<br></br>
        а основным драйвером будет выход на менеджера магазина <br></br>
        сразу же <br></br>
        <br></br>
        с учетом изложенного перестроим материал <br></br>
        </div>
        <div className='col-lg-2'></div>
    </div>
  </div>

  <hr></hr>

  <div className='col-md-12'style={{textAlign:"justify", paddingTop:"0px", padding:"10px", border:"1px solid lightgray"}}>
        Ищите мебель для того чтобы обустроить свой дом?<br></br>
        Вы по адресу. Поможем вам выбрать и купить без стресса и не переплачивая.<br></br>
        <br></br>
        Для Вас прямо сейчас и в наличии красивая, аргономичная и безопасная мебель с гарантией от производителя и быстрой доставкой <br></br>
        <br></br>
        здесь вы найдете:<br></br>
        и безопасную детскую из гипоаллергенных материалов без острых углов. <br></br>
        и аргономичные прямые и угловые диваны с возможностью их удобно разложить<br></br>
        и комбинируемые модульные, при необходимости заменяющие кресла, столы и пуфики <br></br>
        <br></br>
        наберите вашему личному менеджеру на номер 123_456_789 (доступен ватсап, телеграм) 
        и он произведет для вас подбор позиций под ваши требования <br></br>
        а результат сбросит вам в удобный вам мессенджер
        (если хочется искать мебель самостоятельно (а здесь более 10_000 позиций) посмотрите наши
        подборки мебели для разных семей и ситуаций по ссылке: ссылка)<br></br>
  </div>


  <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>Выводы и дальнейшая модификация</h6>          
          <div style={{paddingTop:"5px"}}>озвучим выводы и произведем финальную модификацию, избавившись уже не от лишнего, 
            но от малозначительного (увеличив акцент на оставшихся идеях)
          </div>
          <div style={{paddingTop:"5px"}}>~
          </div>
    </div>

    <div className='col-md-12'style={{textAlign:"justify", paddingTop:"0px"}}>
      <div className='row'>
        <div className='col-lg-2'></div>
        <div className='col-lg-8'>
        заметим, мы сразу же выделили, что в магазине отсуствует выход на специализированные каналы продаж <br></br>
        то есть они предлагают просто ковыряться в своём каталоге мебели бедному покупателю <br></br>
        что очевидно продаж им не добавляет <br></br>
        <br></br>
        в нашем сценарии мы перехватываем покупателя сразу же <br></br>
        заходя с его точки зрения, и выводим его на экшн драйвер <br></br>
        <br></br>
        таким образом наш материал <br></br>
        превратился в довольно стройную систему <br></br>
        <br></br>
        (захват внимания клиента)<br></br>
        (остановка)<br></br>
        (траст : и похвала)<br></br>
        (выделение идеи)<br></br>
        (экшн драйвер)<br></br>
        (псевдо альтернатива (с очевидным не желанием её реализовывать))<br></br>
        <br></br>
        теперь мы легко сможем модифицировать её (системы) части <br></br>
        под требуемую нам ситуацию или сценарий <br></br>
        как видите мы не только модернизировали материал, но и фактически создали модель продаж <br></br>
        <br></br>
        однако мы можем еще больше сократить материал<br></br>
        оставив только вот это (так как сокращение все равно не влияет на структуру)<br></br>
        <br></br>
        текст станет более четким и агрессивным (следовательно будет лучше продавать)<br></br>
        просто заменим траст цифрой довольных покупателей <br></br>
        </div>
        <div className='col-lg-2'></div>
    </div>
  </div>    

  <hr></hr>
  
  <div className='col-md-12'style={{textAlign:"justify", paddingTop:"0px", padding:"10px", border:"1px solid lightgray"}}>
        Ищите мебель для того чтобы обустроить свой дом?<br></br>
        Вы по адресу. <br></br>
        Присоединяйтесь к десяткам тысяч довольных клиентов <br></br>
        Поможем вам выбрать и купить без стресса и не переплачивая.<br></br>        
        <br></br>
        наберите вашему личному менеджеру на номер 123_456_789 (доступен ватсап, телеграм) 
        и он произведет для вас подбор позиций под ваши требования, <br></br>
        а результат сбросит вам в удобный вам мессенджер
        (если хочется искать мебель самостоятельно (а здесь более 10_000 позиций) посмотрите наши
        подборки мебели для разных семей и ситуаций по ссылке: ссылка)<br></br>
  </div>  

  <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>
    </>
  )
}

export default ПримерыРабот