import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'

function НалаживаниеПотокаМатериалов() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.продукты_раздел_налаживаниеПотокаМатериалов["title"]}</title>
    </Helmet>      
  {/* это у нас шапка */}
  <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Налаживание Потока Материалов</h1>          
        </div>
    </div>
    <div>&nbsp;</div>

  {/* картинка с эмоцией */}
  <Card className="text-white position-relative"
    style={{
      background: "url(prodImages/006.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      minHeight:"350px"
    }}
  >
  {/*Card.Img className="img-fluid" src="prodImages/006.jpg" alt="" />*/}
  <div className="card-overlay"></div>
  <Card.ImgOverlay 
    style={{
      backgroundImage: "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.1), rgba(0,0,0,0.6))",
      backgroundSize: "200% auto",
      backgroundPosition: "center center",
      transition: "background-position 0.5s ease-out",
    }}    
    >
    <Card.Title style={{fontSize:"32px", textAlign:"center"}}>динамичное размещение материалов компании по каналам</Card.Title>
    <Card.Body style={{textAlign:"center", fontSize:"30px"}}>&bull; &bull; &bull;</Card.Body>
  </Card.ImgOverlay>
  </Card>

  <div>&nbsp;</div>

  {/* краткое описание продукта */}
  <Card>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            Мы создадим эффективную систему создания и распространения материалов компании по доступным каналам, исходя из бюджета и намерений заказчика. Руководству и собственникам больше не придётся переживать о том, что об их компании никто не знает или она слабо представлена.
{' '}
          </p>
          <footer className="blockquote-footer">
            все исполнение производится на базе 
            <Link to={SiteStructure.entries.гарантии_раздел_стандартизацияРабот.url}> <b>стандартов</b> </Link>
            и поставленной
            <Link to={SiteStructure.entries.гарантии_раздел_методологияРабот.url}> <b>методологии</b></Link>
          </footer>
        </blockquote>
      </Card.Body>
    </Card>

    {/* клиент получит */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Клиент Получит:</h1>          
        </div>
    </div>
    <div>&nbsp;</div>

    {/* клиент получит перечисление */}
    <div className="row">

    <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          &bull; Список каналов представления 
          </Card.Text>
        </Card.Body>         
      </Card>
      </div>
      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          &bull; Стоимостные характеристики и отдача 
          </Card.Text>
        </Card.Body>         
      </Card>
      </div>
      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          &bull; Представленные конкуренты 
          </Card.Text>
        </Card.Body>         
      </Card>
      </div>
      <div>&nbsp;</div>
      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          &bull; Адекватный контент план 
          </Card.Text>
        </Card.Body>         
      </Card>
      </div>      
      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          &bull; Экспертные публикации на уровне кандидата наук 
          </Card.Text>
        </Card.Body>         
      </Card>
      </div>   

    </div>

    <div>&nbsp;</div>    

    {/* производимые работы  */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Производимые работы:</h1>          
        </div>
    </div>
    <div>&nbsp;</div>

    {/* три составные части */}
    <div className="row">

    <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>АНАЛИТИКА</Card.Title>
          <Card.Text>
          Свод аналитики с рынка, включающий аудитории, отклики, отзывы о каналах
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/education.svg" fluid /></div>        
        </div>
      </Card>
      </div>

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>СОЗДАНИЕ</Card.Title>
          <Card.Text>
          Техническая реализация итоговых материалов в форматах, сразу предполагающих их использование 
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/certificates.svg" fluid /></div>
        </div>
      </Card>
      </div>      

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title style={{textAlign:"center"}}>ДИНАМИКА</Card.Title>
          <Card.Text>
            Динамика публикаций достаточная для заказчика, выше чем у конкурентов 
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
        <div style={{ flexShrink: 0, minHeight:"30px", textAlign:"center" }}>
        <div className="col-lg-12" style={{maxWidth:"50px", maxHeight:"50px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/document.svg" fluid /></div>    
        </div>
      </Card>
      </div>
      
    </div>

    {/* производимые работы  */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Ценообразование и порядок заказа:</h1>          
        </div>
    </div>
    <div>&nbsp;</div>

    <Card>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            Согласно нашего 
            <Link to={SiteStructure.entries.гарантии_раздел_схемаРаботы.url}> <b>установленного порядка работ</b></Link>
            
            , работы выполняются на первом этапе лишь в рамках
            комплексного приведения материалов заказчика к метологии и стандартам в рамках одного единого контракта.<br></br>
            В дальнейшем, по отдельной договоренности (что подразумевается крайне редко), компания может дорабатывать материалы.
            {' '}
          </p>          
        </blockquote>
      </Card.Body>
    </Card>

  <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div style={{textAlign:"center","fontSize":"26px"}}>Будем рады видеть Вас нашими клиентами.<br></br>Обращайтесь по телефону указанному в шапке сайта.</div>

    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>
    </>
  )
}

export default НалаживаниеПотокаМатериалов