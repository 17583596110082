import React from 'react';
import SiteStructure from "../routingStructureAndMenu/SiteStructure"
import {Helmet} from "react-helmet"
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'

function СтандартыРабот() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{SiteStructure.entries.гарантии_раздел_стандартизацияРабот["title"]}</title>
    </Helmet>      
    {/* это у нас шапка */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Наши стандарты</h1>          
          <div style={{paddingTop:"5px"}}>ориентир на стандарты позволяет становиться результату управляемым и прогнозируемым</div>
        </div>
    </div>
    <div>&nbsp;</div>
    <hr style={{width:"50%", margin:"auto", marginBottom:"7px"}}></hr>

    {/*это у нас стилистическая перебивка */}
    <div className="col-lg-12" style={{margin:"auto", marginBottom:"30px"}}><Image src="/pageImages/standards.png" fluid /></div>

    {/* теперь содержание из блоков */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"0px"}}>
          <h1 style={{fontWeight:"bolder"}}>Стандарты во главе</h1>          
          <div style={{paddingTop:"5px"}}>все создаваемые материалы базируются на стандартах, у заказчика есть полное понимание того, по
            какой причине тот или иной материал создан именно таким образом и способом.
          </div>
        </div>
    </div>
    <div>&nbsp;</div>

    {/* зона на текущий момент стандартизировано */}
    <div className="row" style={{paddingTop:"10px", paddingBottom:"20px"}}>
      <div className="col-md-12">
        <h4 style={{margin:"auto", textAlign:"center"}}>На текущий момент у нас стандартизировано (материалы динамически дополняются):</h4>
        <div>&nbsp;</div>
      </div>

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          <div>&bull; Общие требования к текстам</div>
          <div>&bull; Интервью руководителя или специалиста</div>
          <div>&bull; Описание продукта</div>
          <div>&bull; Представление компании</div>
          <div>&bull; Текст новости</div>
          <div>&bull; Текст публикации</div>
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
      </Card>
      </div>  

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          <div>&bull; Анонс мероприятия события</div>
          <div>&bull; Итоги мероприятия события</div>
          <div>&bull; Сервисное сообщение о сотрудничестве</div>
          <div>&bull; Прайс лист - список услуг</div>
          <div>&bull; Вводное слово руководителя</div>
          <div>&bull; Акция или предложение</div>
          <div>&bull; Коммерческое предложение композит</div>
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
      </Card>
      </div>  

      <div className="col-md-4  d-flex align-items-stretch">
      <Card style={{ width: '100%', display: 'flex', flexDirection: 'column', border:"0px solid white" }}>
        <Card.Body style={{ flex: '1 0 auto' }}>
          <Card.Text>
          <div>&bull; Поздравление или нотификация</div>
          <div>&bull; Проф био сотрудника</div>
          <div>&bull; Кейс оказанная услуга</div>
          <div>&bull; Комментарий специалиста</div>
          <div>&bull; Карьерные возможности</div>
          <div>&bull; Пресс релиз действий вне продукта</div>
          </Card.Text>
        </Card.Body>
         {/* Футер карточки */}
      </Card>
      </div>  


        <div>&nbsp;</div>
    </div>

    {/* основная структура стандартов */}
    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h1 style={{fontWeight:"bolder"}}>Основная структура стандартов</h1>          
          <div style={{paddingTop:"5px"}}>стандарты представляют собой документы передаваемые заказчику, их основная структура представлена ниже (она может варьироваться и быть уточненной под деятельность заказчика)</div>
        </div>
    </div>

    <div className="row">
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТРЕБОВАНИЯ К ОСНОВНОЙ ЦЕЛИ И ЗАДАЧЕ </h6>          
          <div style={{paddingTop:"5px"}}>раздел включает: (-1-) Цель материала, (-2-) Процесс продажи, (-3-) Моменты, обязательные к акцентированию, 
          (-4-) Возможная эволюция материала</div>
        </div>
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТРЕБОВАНИЯ К РАЗДЕЛАМ </h6>          
          <div style={{paddingTop:"5px"}}>раздел включает общие требования к материалу, согласно его разделов, как то, например необходимость 
            включения в описание продукта указания на приоритетность клиента для компании и требования к ограниченности предложения 
          </div>
        </div>
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТРЕБОВАНИЯ К ИЗОБРАЖЕНИЯМ </h6>          
          <div style={{paddingTop:"5px"}}>раздел включает общие требования к изображениям, ассоциированным с материалом и если нужно порядок 
            их получения 
          </div>
        </div>
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТРЕБОВАНИЯ К ТЕКСТУ </h6>          
          <div style={{paddingTop:"5px"}}>раздел включает общие требования к тексту, разделенные на ОБЩИЕ и КОНКРЕТНО К ЭТОМУ ТИПУ МАТЕРИАЛОВ, 
            разделенные в свою очередь на обязательные, предпочтительные и запрещенные
          </div>
        </div>
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТРЕБОВАНИЯ К ОФОРМЛЕНИЮ </h6>          
          <div style={{paddingTop:"5px"}}>раздел включает общие требования к оформлению материала для PDF, веб, карточек в социальной сети, динамических презентаций 
          </div>
        </div>
        <div className='col-md-12' style={{textAlign:"center", paddingTop:"20px"}}>
          <h6 style={{fontWeight:"bolder"}}>ТЕХНИЧЕСКИЕ ПОЛЯ ДЛЯ РАЗДЕЛОВ</h6>          
          <div style={{paddingTop:"5px"}}>раздел включает перечисление технических полей для автоматизации задач по созданию подобных материалов
          </div>
        </div>
    </div>


    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div className="row">      
    <div className="col-lg-12" style={{maxWidth:"80px", maxHeight:"80px", margin:"auto", marginBottom:"30px"}}><Image src="/simpleVectors/quill.svg" fluid /></div> 
    </div>

    <div>&nbsp;</div>

    </>
  )
}

export default СтандартыРабот